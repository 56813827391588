// ############  var  ############
var winWidth    = $(window).width();
var winHeight   = $(window).height();
var scrollTop   = $(window).scrollTop();
var breakpoint = {'pc':1200,'tb':992,'sp':768};
var headerTag   = $('.l-header');
var ua = navigator.userAgent.toLowerCase();


// ############  execute  ############
$(function($) {
  pageTop();
  smoothScroll();
  objectFitImages('.js-ofi img');
});

$(window).on('load', function() {
  ua = navigator.userAgent.toLowerCase();
  iosCheck();
  deviceCheck();
  animation();
});

$(window).on('resize', function() {
  ua = navigator.userAgent.toLowerCase();
  winWidth = $(window).width();
  winHeight = $(window).height();
  iosCheck();
  deviceCheck();
});

$(window).on('scroll', function() {
  scrollTop = $(window).scrollTop();
  pageTop();
  animation();
});


// ############  function  ############
function iosCheck() {
  if ( ua.indexOf('ipad') > -1 || ua.indexOf('iphone') > -1 || (ua.indexOf('macintosh') > -1 && 'ontouchend' in document ) ) {
    $('body').addClass('ios');
  }
}


function deviceCheck() {
  if (ua.indexOf('iphone') > -1 || ua.indexOf('ipod') > -1 || ua.indexOf('android') > -1 && ua.indexOf('mobile') > -1) {
    $('body').removeClass('pc tablet').addClass('sp');
  } else if (ua.indexOf('ipad') > -1 || ua.indexOf('Android') > -1 || (ua.indexOf('macintosh') > -1 && 'ontouchend' in document ) ) {
    $('body').removeClass('pc sp').addClass('tablet');
  } else {
    $('body').removeClass('tablet sp').addClass('pc');
  }
}


function pageTop() {
  if ( scrollTop > 100 ) {
    $('.js-pagetop').stop().fadeIn("slow");
  } else {
    $('.js-pagetop').stop().fadeOut();
  }
}


function smoothScroll() {
  $('.js-scroll:not([href=""])').on('click', function() {
    var speed = 600;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    if ( winWidth >= breakpoint['tb'] ) {
      var headHeight = 0;
    } else {
      var headHeight = headerTag.outerHeight();
    }
    var position = target.offset().top - headHeight;
    if ( href == '#' ) { position = 0; }
    $('body,html').animate({scrollTop:position}, speed, 'swing');
    return false;
  });
}


function animation() {
  $('.js-anime:not(.is-anime)').each(function(){
    var self = $(this),
        targetPosition = self.offset().top,
        triggerPosition;
    if(winWidth >= breakpoint['tb']) {
      triggerPosition = targetPosition - winHeight + ( winHeight / 5 );
    } else {
      triggerPosition = targetPosition - winHeight + ( winHeight / 10 );
    }
    if( scrollTop > triggerPosition ){
      self.addClass("is-anime");
    }
  });
}