function slideout_menu(){
  var slideout = new Slideout({
    'panel': document.getElementById('js-so_panel'),
    'menu': document.getElementById('js-so_menu'),
    'duration': 10,
  });
  var nodeList = document.querySelectorAll('#js-so_toggle, #js-so_panel');
  var node = Array.prototype.slice.call(nodeList,0);
  node.forEach(function(elem, index){
    elem.addEventListener('click', function() {
      slideout.toggle();
    });
  });
}


$(function() {
/*********************************************************************
  common
*********************************************************************/
  slideout_menu();
  $('.js-key_slide').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    speed: 2000,
    dots:false,
    arrows:false,
    autoplay: true,
    fade: false,
    swipe:false,
    variableWidth: true,
    rtl:true,
    easing:'ease-in',
    //レスポンシブでの動作を指定
    responsive:[
      {
          breakpoint: 992,
          settings:{
            variableWidth: false,
          }
      },
    ]
  });
  $('.js-pulldown_trg').hover(
    function(){
      $(this).addClass('is-act');
    },
    function(){
      $(this).removeClass('is-act');
    },
  );
  $('.js-pulldown_trg').on('click',function(){
    if($(this).hasClass('is-act')){
      $(this).removeClass('is-act');
    }else{
      $(this).addClass('is-act');
    }
  });

/*********************************************************************
  index
*********************************************************************/

});


$(window).on('scroll',function(){
});

$(window).on('resize',function(){
});

$(window).on('load',function(){
});